(function categoryLinks() {
  const clickableCategory = document.querySelectorAll("[data-category-href]");
  // eslint-disable-next-line func-names,no-use-before-define
  clickableCategory.forEach(forward);

  function forward(item) {
    const link = item.dataset.categoryHref;

    item.addEventListener("click", (e) => {
      e.preventDefault();
      setTimeout(
        () => {
          window.location = link;
        },
        0,
        this.href,
      );
    });
  }
})();
