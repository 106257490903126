/**
 * Show an onLoad Animation until everything is loaded
 */
(function onloadAnimation() {
  const onloadContainer = document.querySelector("[data-onload-animation]");
  if (!onloadContainer) {
    return;
  }

  const { onloadAnimation, onloadFinish, onloadDuration } =
    onloadContainer.dataset;
  const animationDuration = parseInt(onloadDuration);
  window.addEventListener("load", function () {
    window.setTimeout(() => {
      onloadContainer.classList.add(onloadAnimation);
      window.setTimeout(
        () => onloadContainer.classList.add(onloadFinish),
        animationDuration,
      );
    }, 1);
  });
})();
