var x, i, j, selElmnt, a, b, c;
/* Look for any elements with the class 'custom-select': */
x = document.querySelectorAll("[data-form__select-custom]");
a = [];
b = [];

for (i = 0; i < x.length; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];

  /* For each element, create a new DIV that will act as the selected item: */
  a[i] = document.createElement("DIV");
  a[i].setAttribute("class", "form-element__select-custom-box");
  a[i].innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;

  if (selElmnt.hasAttribute("required")) {
    a[i].innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML + "*";
  } else {
    a[i].innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  }

  x[i].appendChild(a[i]);

  /* For each element, create a new DIV that will contain the option list: */
  b[i] = document.createElement("DIV");
  b[i].setAttribute(
    "class",
    "form-element__select-custom-items form-element__select-custom-hide",
  );

  for (j = 1; j < selElmnt.length; j++) {
    /* For each option in the original select element, create a new DIV that will act as an option item: */
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.setAttribute("data-value", selElmnt.options[j].value);
    c.classList.add("select-items-inner");

    c.addEventListener("click", function () {
      /* When an item is clicked, update the original select box, and the selected item: */
      var y, i, k, s, h;
      s = this.parentNode.parentNode.getElementsByTagName("select")[0];
      h = this.parentNode.previousSibling;
      for (i = 0; i < s.length; i++) {
        if (s.options[i].innerHTML == this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName(
            "form-element__select-custom--is-selected",
          );
          for (k = 0; k < y.length; k++) {
            y[k].classList.remove("form-element__select-custom--is-selected");
          }
          this.setAttribute(
            "class",
            "select-items-inner form-element__select-custom--is-selected",
          );
          break;
        }
      }
      h.click();
    });
    b[i].appendChild(c);
  }
  x[i].appendChild(b[i]);

  x[i].addEventListener("click", function () {
    this.classList.remove("error");
  });
  x[i].addEventListener("touchend", function () {
    this.classList.remove("error");
  });

  a[i].addEventListener("click", function (e) {
    /* When the select box is clicked, close any other select boxes, and open/close the current select box: */
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle("form-element__select-custom-hide");
    this.classList.toggle("form-element__select-custom-box--is-active");
  });

  b[i].addEventListener("click", function () {
    this.previousSibling.classList.add("has--selected-value");
  });
}

function closeAllSelect(elmnt) {
  /* A function that will close all select boxes in the document, except the current select box: */
  var x,
    y,
    i,
    arrNo = [];
  x = document.getElementsByClassName("form-element__select-custom-items");
  y = document.getElementsByClassName("form-element__select-custom-box");
  for (i = 0; i < y.length; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i);
    } else {
      y[i].classList.remove("form-element__select-custom-box--is-active");
    }
  }
  for (i = 0; i < x.length; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("form-element__select-custom-hide");
    }
  }
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener("click", closeAllSelect);
