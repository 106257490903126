(function languageSwitch() {
  const languageButton = document.querySelector(
    "[data-current-language-select]",
  );
  const OPENCLASS = "is--open";

  function toogleDropDown(e) {
    e.preventDefault();
    const { parentElement } = languageButton;

    if (parentElement.classList.contains(OPENCLASS)) {
      parentElement.classList.remove(OPENCLASS);
    } else {
      parentElement.classList.add(OPENCLASS);
    }
  }

  languageButton?.addEventListener("click", toogleDropDown);
})();
