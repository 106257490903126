/**
 * Check if an input already has a value on site load. e.g. after using history back
 */
(function formPrevent() {
  window.addEventListener("load", function () {
    const formElementContainerStack = document.querySelectorAll(
      ".form-element__container",
    );

    for (let i = 0; i < formElementContainerStack.length; i++) {
      let inputs = formElementContainerStack[i].getElementsByTagName("input");
      let selects = formElementContainerStack[i].getElementsByTagName("select");
      let textareas =
        formElementContainerStack[i].getElementsByTagName("textarea");

      for (let j = 0; j < inputs.length; j++) {
        if (
          inputs[j].type != "hidden" &&
          inputs[j].type != "radio" &&
          inputs[j].type != "checkbox" &&
          inputs[j].value != "" &&
          inputs[j].value != " "
        ) {
          inputs[j].classList.add("form-element--has-value");
        }
      }
      for (let k = 0; k < selects.length; k++) {
        if (selects[k].value != "" && selects[k].value != " ") {
          formElementContainerStack[i].classList.remove("is--locked");
          let customSelect = formElementContainerStack[
            i
          ].getElementsByClassName("form-element__select-custom-box");
          customSelect[0].classList.add("form-element--has-value");
        }
      }
      for (let j = 0; j < textareas.length; j++) {
        if (
          textareas[j].type != "hidden" &&
          textareas[j].type != "radio" &&
          textareas[j].type != "checkbox" &&
          textareas[j].value != "" &&
          textareas[j].value != " "
        ) {
          textareas[j].classList.add("form-element--has-value");
        }
      }
      if (formElementContainerStack[i].hasAttribute("data-form-onload-error")) {
        formElementContainerStack[i].classList.add("error");
      }
    }
  });
})();
