import { createPopper } from "@popperjs/core";

/* Code for tooltips using @popperjs. */
const popperElem = document.createElement("div");
const popperText = document.createElement("div");
const popperArrow = document.createElement("div");
popperElem.classList.add("popper");
popperElem.setAttribute("role", "tooltip");
popperArrow.classList.add("popper__arrow");
popperArrow.dataset.popperArrow = "";
popperText.classList.add("popper__text");
popperElem.appendChild(popperArrow);
popperElem.appendChild(popperText);
document.body.appendChild(popperElem);
let currentPopper;
document.querySelectorAll("[title]").forEach((elem) => {
  const popper = createPopper(elem, popperElem, {
    modifiers: [
      {
        name: "offset",
        options: { offset: [0, 16] },
      },
      {
        name: "preventOverflow",
        options: { padding: 16 },
      },
    ],
  });
  const isHtml = elem.hasAttribute("data-tooltip-html");
  const isOnClick = elem.hasAttribute("data-tooltip-on-click");
  if (isOnClick) {
    elem.dataset.tooltip = elem.getAttribute("title");
    elem.removeAttribute("title");
  }

  const enablePopper = (enabled) =>
    popper.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: "eventListeners", enabled }],
    }));
  const reset = () => {
    if (!currentPopper) {
      return;
    }
    if (!isOnClick) {
      currentPopper.setAttribute(
        "title",
        isHtml ? popperElem.dataset.tooltipHtml : popperElem.dataset.tooltip,
      );
    }
    popperElem.removeAttribute("tabindex");
    popperElem.dataset.tooltip = "";
    popperElem.dataset.tooltipHtml = "";
    popperText.innerHTML = "";
    currentPopper = undefined;
  };
  const show = () => {
    reset();
    if (!elem.dataset.tooltip && !elem.getAttribute("title")) {
      return;
    }
    currentPopper = elem;
    if (isHtml) {
      if (isOnClick) {
        popperElem.setAttribute("tabindex", "-1");
      } else {
        popperElem.removeAttribute("tabindex");
      }
      popperElem.dataset.tooltip = "";
      const html = elem.dataset.tooltip || elem.getAttribute("title");
      popperElem.dataset.tooltipHtml = html;
      popperText.innerHTML = html;
    } else {
      popperElem.removeAttribute("tabindex");
      popperElem.dataset.tooltip =
        elem.dataset.tooltip || elem.getAttribute("title");
      popperElem.dataset.tooltipHtml = "";
      popperText.innerHTML = "";
    }
    elem.setAttribute("title", "");
    popperElem.classList.add("popper--visible");
    return popper
      .update()
      .then(() => enablePopper(true))
      .then(() => {
        if (isHtml && isOnClick) {
          popperElem.focus();
        }
        return true;
      })
      .catch(console.error);
  };
  const hide = () => {
    reset();
    popperElem.classList.remove("popper--visible");
    return enablePopper(false);
  };
  const toggle = () => (currentPopper === elem ? hide() : show());
  if (isOnClick) {
    ["click"].forEach((event) => elem.addEventListener(event, toggle));
    if (isHtml) {
      ["blur"].forEach((event) => popperElem.addEventListener(event, hide));
    } else {
      ["focus"].forEach((event) => elem.addEventListener(event, show));
      ["blur"].forEach((event) => elem.addEventListener(event, hide));
    }
  } else {
    ["mouseenter", "focus"].forEach((event) =>
      elem.addEventListener(event, show),
    );
    ["mouseleave", "blur"].forEach((event) =>
      elem.addEventListener(event, hide),
    );
  }
});

/* Other tooltip code showing an image in a bigger popup */
document.querySelectorAll("[data-tooltip]").forEach((tooltip) => {
  tooltip.addEventListener("touchstart", () =>
    this.classList.add("is-hover-simulated"),
  );
  tooltip.addEventListener("touchend", () =>
    this.classList.remove("is-hover-simulated"),
  );
});
