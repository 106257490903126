(function fileupload() {
  const uploadContainer = document.querySelectorAll(
    "[data-form-element-upload]",
  );

  const input = [];
  const uploadButton = [];
  const label = [];
  const labelVal = [];
  const deleteButton = [];
  const addButton = [];

  // Init Upload Field
  Array.prototype.forEach.call(uploadContainer, (uploadElement, key) => {
    input[key] = uploadElement.children[0].children[0];
    uploadButton[key] = uploadElement.children[0].children[1];
    label[key] = uploadElement.children[0].children[2];
    labelVal[key] = label.innerHTML;
    deleteButton[key] = uploadElement.children[1]; // data-multiupload-delete-filesdata-multiupload-delete-files
    addButton[key] = uploadElement.children[2]; // data-multiupload-delete-filesdata-multiupload-delete-files

    // show next input
    if (addButton[key]) {
      addButton[key].addEventListener("click", function () {
        if (this.classList.contains("button--is-locked")) return;

        if (uploadContainer[key + 1]) {
          // show next input
          uploadContainer[key + 1].classList.remove("organism--is-hidden");

          // hide button
          deleteButton[key].classList.add("button--is-hidden");
          deleteButton[key + 1].classList.remove("button--is-hidden");

          addButton[key].classList.add("button--is-hidden");
          addButton[key + 1].classList.remove("button--is-hidden");
        }
      });
    }

    // add event listener
    if (addButton[key]) {
      let fileName = "";

      input[key].addEventListener("change", function (e) {
        if (this.files && this.files.length > 1)
          fileName = (this.getAttribute("data-multiple-caption") || "").replace(
            "{count}",
            this.files.length,
          );
        else fileName = e.target.value.split("\\").pop();

        if (fileName) label[key].innerHTML = fileName;
        else label[key].innerHTML = labelVal[key];

        if (this.files.length > 0) {
          deleteButton[key].classList.remove("button--is-locked");

          if (key < uploadContainer.length - 1) {
            addButton[key].classList.remove("button--is-locked");
          }
        }
      });
    }

    if (key === 0) {
      // refresh max-files message
      uploadElement.dataset.maxFiles = uploadElement.dataset.maxFiles.replace(
        "#maxFiles#",
        uploadContainer.length,
      );

      deleteButton[key].addEventListener("click", function () {
        input[key].classList.remove("form-element--has-value");
        input[key].value = "";
        label[key].innerHTML = null;
        deleteButton[key].classList.add("button--is-locked");
        addButton[key].classList.add("button--is-locked");
      });
    } else {
      deleteButton[key].addEventListener("click", function () {
        input[key].classList.remove("form-element--has-value");
        input[key].value = "";
        label[key].innerHTML = null;

        uploadElement.classList.add("organism--is-hidden");

        if (addButton[key - 1]) {
          addButton[key - 1].classList.remove("button--is-hidden");
          deleteButton[key - 1].classList.remove("button--is-hidden");
        }
      });
    }
  });
})();
