/* eslint-disable import/prefer-default-export, no-return-await */
/**
 * Fetch with timeout.
 *
 * @function
 * @param {string} uri - The API URL.
 * @param {object} options - Options object.
 * @param {number} time - Number in milliseconds until abort.
 */
const fetchWithTimeout = (uri, options = {}, time = 5000) => {
  const controller = new AbortController();
  const config = { ...options, signal: controller.signal };

  setTimeout(() => {
    controller.abort();
  }, time);

  return fetch(uri, config)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }

      return response;
    })
    .catch((error) => {
      if (error.name === "AbortError") {
        throw new Error("Response timed out");
      }

      throw new Error(error.message);
    });
};

/**
 * @function
 * @param {string} uri - The API URL.
 */
export const getData = async (uri) =>
  await fetchWithTimeout(uri, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((r) => r.json());
