(function popupControl() {
  // Function Constants
  const popupTrigger = document.querySelectorAll("[data-open-popup]");

  for (let i = 0; i < popupTrigger.length; i++) {
    const popupElement = document.querySelectorAll(
      `[data-popup-identifier="${popupTrigger[i].dataset.popupTarget}"]`,
    );

    if (popupElement.length <= 0) {
      console.log("No PopUp Element available. Check HTML...");
      return;
    }
    if (popupElement.length > 1) {
      console.log(
        "More than one PopUp Element available for defined ID. Check HTML...",
      );
      return;
    }

    popupTrigger[i].addEventListener("click", function () {
      const popupElement = document.querySelectorAll(
        `[data-popup-identifier="${this.dataset.popupTarget}"]`,
      );
      const { popupType } = this.dataset;
      let popupClose = null;

      // get close button
      for (let j = 0; j < popupElement[0].children.length; j++) {
        if (popupElement[0].children[j].className == "popup-close") {
          popupClose = popupElement[0].children[j];
          break;
        }
      }

      // close
      popupClose.addEventListener("click", () => {
        popupElement[0].classList.remove("popup--is-open");
      });

      switch (popupType) {
        case "language": {
          const navigation = document.querySelectorAll(".navigation");
          const navigationContainer = navigation[0].querySelectorAll(
            ".navigation__menu-container",
          );

          if (popupElement[0].classList.contains("popup--is-open")) {
            popupElement[0].classList.remove("popup--is-open");
          } else {
            popupElement[0].classList.add("popup--is-open");
          }

          if (
            navigationContainer[0].classList.contains(
              "navigation__menu-container--is-visible",
            )
          ) {
            popupElement[0].style.top = "0px";
            popupElement[0].style.left = "0px";
            window.scrollTo(0, 0);
          } else {
            const positionData = this.parentElement.getBoundingClientRect();
            //const parentHeight = this.parentElement.offsetHeight;
            popupElement[0].style.top = `${parseInt(window.pageYOffset) + 100}px`;
            popupElement[0].style.left = `${parseInt(positionData.left) - 60}px`;
          }
          break;
        }
        default:
          break;
      }
    });
  }
})();
