import { counter } from "../counter";

(function newCounter() {
  const counterItem = document.querySelectorAll("[data-counter-item]");

  if (counterItem.length === 0) {
    return;
  }

  function dateCounter() {
    counterItem.forEach((element) => {
      if (!element.hasAttribute("data-start-time")) {
        return;
      }
      const counterValueStart = parseInt(element.dataset.startValue, 10);
      const counterValueEnd = parseInt(element.dataset.counterMax, 10);
      const itemFromDate = parseInt(element.dataset.startTime, 10);
      const itemToDate = parseInt(element.dataset.startEnd, 10);
      const itemCurrentDate = Math.floor(Date.now() / 1000);
      const currentValue =
        (counterValueStart +
          (itemCurrentDate - itemFromDate) *
            (counterValueEnd - counterValueStart)) /
        (itemToDate - itemFromDate);
      const newValue = Math.round(currentValue);

      if (
        (newValue > 9999 && !Number.isInteger(newValue)) ||
        newValue > 99999
      ) {
        element.classList.add("counter__number--longnum");
      }

      // eslint-disable-next-line no-param-reassign
      element.innerHTML = newValue.toLocaleString("de-DE");

      if (itemToDate <= itemCurrentDate) {
        element.innerHTML = counterValueEnd.toLocaleString("de-DE");
        clearInterval();
      }
    });
  }

  // eslint-disable-next-line consistent-return
  counterItem.forEach((element) => {
    if (!element.hasAttribute("data-start-time")) {
      counter();
    } else {
      dateCounter();
    }
  });

  setInterval(dateCounter, 60000);
})();
