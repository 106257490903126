import { css } from "lit";

export default css`
  :host {
    --size: 80vh;
    --text-inset-inline-start: 7vw;
    --text1-inline-size: 40vw;
    --text2-inline-size: 86vw;
    --sand-inline-size: 150vw;
    --sand-inset-inline-start: -50vw;
    --dune-inline-size: 200vw;
    --dune-inset-inline: auto 0;
    --seagull-inline-size: 9.375rem;
    --seagull-inset-block-start: 18vh;
    --seagull-inset-inline-start: 2vw;
    --people-inline-size: 20rem;
    --people-inset-inline-start: calc(52% - 11rem);
    --bole-block-size: 8rem;
    --bole-inset-inline-end: 3vw;
    --product-inline-size: 30rem;
    --product-inset-block-end: calc(0.69 * var(--bole-block-size));
    --product-inset-inline-end: calc(var(--bole-inset-inline-end) - 8.3rem);

    display: block;
  }

  .sentinel {
    block-size: 0;
    transform: translateY(calc(100vh - var(--size) + 1px));
  }

  .hero {
    block-size: var(--size);
    inline-size: 100vw;
    overflow: hidden;
    position: relative;
  }

  .hero__item {
    display: flex;
    position: absolute;
    inline-size: 100vw;
  }

  img {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
  }

  .hero__sky {
    block-size: 60vh;
    inset-block-start: 0;
    inset-inline-start: 0;
  }

  .hero__beach {
    block-size: 40vh;
    inset-block-start: 60vh;
    inset-inline-start: 0;
  }

  .hero__text {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-000);
    inset-block-end: 41vh;
    inset-inline-start: var(--text-inset-inline-start);
  }

  .hero__text-1 {
    inline-size: var(--text1-inline-size);
  }

  .hero__text-2 > img {
    inline-size: var(--text2-inline-size);
  }

  .hero__text-2 {
    overflow: hidden;
  }

  .hero__seagull {
    block-size: auto;
    inline-size: var(--seagull-inline-size);
    inset-block-start: var(--seagull-inset-block-start);
    inset-inline-start: var(--seagull-inset-inline-start);
  }

  .hero__people {
    block-size: auto;
    inline-size: var(--people-inline-size);
    inset-block-start: 61vh;
    inset-inline-start: var(--people-inset-inline-start);
  }

  .hero__sand {
    inline-size: var(--sand-inline-size);
    inset-block-end: 0;
    inset-inline-start: var(--sand-inset-inline-start);
  }

  .hero__dune {
    inline-size: var(--dune-inline-size);
    inset-block-end: 0;
    inset-inline: var(--dune-inset-inline);
  }

  .hero__bole {
    block-size: var(--bole-block-size);
    inline-size: auto;
    inset-block-end: 0;
    inset-inline-end: var(--bole-inset-inline-end);
    transform-origin: top;
  }

  .hero__product {
    block-size: auto;
    inline-size: var(--product-inline-size);
    inset-block-end: var(--product-inset-block-end);
    inset-inline-end: var(--product-inset-inline-end);
    transform-origin: bottom;
  }

  .hero__product > img {
    block-size: 100%;
    inset: 0;
    position: absolute;
    visibility: hidden;
  }

  .hero__product > img:first-child {
    position: static;
    visibility: visible;
  }

  .hero__seagull,
  .hero__text-2 > img {
    transition: transform var(--hero-duration-slow, 2s) ease-out;
  }

  .hero__sand,
  .hero__dune,
  .hero__people,
  .hero__text-1,
  .hero__bole,
  .hero__product {
    transition: transform var(--hero-duration, 1s) ease-out;
  }

  .hero.animated .hero__text-1 {
    transform: translateX(calc((100% + 20vw) * -1));
  }

  .hero.animated .hero__text-2 > img {
    transform: translateY(200%);
  }

  .hero.animated .hero__seagull {
    transform: scale(1.05) translateX(-50vw) translateY(2vh);
  }

  .hero.animated .hero__people,
  .hero.animated .hero__dune {
    transform: scale(1.2);
  }

  .hero.animated .hero__sand {
    transform: scale(1.2) translateY(2vh);
  }

  .hero.animated .hero__bole {
    transform: scale(1.4) translateX(3vw) translateY(9vh);
  }

  .hero.animated .hero__product {
    transform: scale(1.4) translateX(3vw) translateY(10vh);
  }

  .hero.animated .hero__product img:nth-child(1) {
    animation: animate-bottle 80ms 1 linear forwards 250ms;
  }

  .hero.animated .hero__product img:nth-child(2) {
    animation: animate-bottle 80ms 1 linear forwards 290ms;
  }

  .hero.animated .hero__product img:nth-child(3) {
    animation: animate-bottle 80ms 1 linear forwards 330ms;
  }

  .hero.animated .hero__product img:nth-child(4) {
    animation: animate-bottle 80ms 1 linear forwards 370ms;
  }

  .hero.animated .hero__product img:nth-child(5) {
    animation: animate-bottle 80ms 1 linear forwards 410ms;
  }

  .hero.animated .hero__product img:nth-child(6) {
    animation: animate-bottle 80ms 1 linear forwards 450ms;
  }

  .hero.animated .hero__product img:nth-child(7) {
    animation: animate-bottle 80ms 1 linear forwards 490ms;
  }

  .hero.animated .hero__product img:nth-child(8) {
    animation: animate-bottle-end 80ms 1 linear forwards 530ms;
  }

  @media screen and (width >= 768px) {
    :host {
      --bole-block-size: 11rem;
      --product-inline-size: 34rem;
      --product-inset-inline-end: calc(var(--bole-inset-inline-end) - 5.1rem);
    }
  }

  @media screen and (width <= 992px) {
    .hero__people {
      display: none;
    }
  }

  @media screen and (width >= 992px) {
    :host {
      --text-inset-inline-start: 15vw;
      --text1-inline-size: 30vw;
      --text2-inline-size: 70vw;
      --sand-inline-size: 60vw;
      --sand-inset-inline-start: 0;
      --dune-inline-size: 150vw;
      --dune-inset-inline: -25vw auto;
      --seagull-inset-block-start: 15vh;
      --seagull-inset-inline-start: 5vw;
      --people-inline-size: 30rem;
      --people-inset-inline-start: calc(50% - 14rem);
    }
  }

  @media screen and (width >= 1200px) {
    :host {
      --size: 100vh;
      --text-inset-inline-start: 20vw;
      --text1-inline-size: 20vw;
      --text2-inline-size: 60vw;
      --dune-inline-size: 100vw;
      --dune-inset-inline: 0 auto;
      --bole-inset-inline-end: 18vw;
      --bole-block-size: 13rem;
    }
  }

  @media screen and (width >= 1536px) {
    :host {
      --bole-block-size: 14rem;
      --product-inline-size: 40rem;
      --product-inset-inline-end: calc(var(--bole-inset-inline-end) - 7.5rem);
    }
  }

  @keyframes animate-bottle {
    0% {
      visibility: visible;
    }

    80% {
      visibility: visible;
    }

    81% {
      visibility: hidden;
    }

    100% {
      visibility: hidden;
    }
  }

  @keyframes animate-bottle-end {
    0% {
      visibility: visible;
    }

    100% {
      visibility: visible;
    }
  }
`;
