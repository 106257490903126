(function productFilter() {
  const itemCol = document.querySelectorAll(".results-filter-list .col-12");

  itemCol.forEach((items) => {
    const item = items.getElementsByTagName("li");

    if (item.length <= 1) {
      // eslint-disable-next-line no-param-reassign
      items.style.display = "none";
    }
  });
})();
