import Bouncer from "formbouncerjs"; // TODO very old lib, 5 years no update... get rid of it!

/**
 * Initialize Form Validation
 *
 * @type {Bouncer}
 */
(
  (function formValidation() {
    document.addEventListener(
      "bouncerInitialized",
      function (event) {
        // when clicked 'prev page' on a multi site form no form submitting
        // this is a workaround
        const buttonPrevStack = event.target.querySelectorAll(
          "[data-direction-prev]",
        );
        for (let j = 0; j < buttonPrevStack.length; j++) {
          buttonPrevStack[j].addEventListener("click", function () {
            // disable custom event handling
            event.detail.settings.emitEvents = false;

            // just submit the form
            const hiddenField = event.target.querySelectorAll(
              "[data-input-hidden-current-page]",
            );
            if (hiddenField.length > 0) {
              // The field with the error
              hiddenField[0].setAttribute(
                "value",
                buttonPrevStack[j].getAttribute("value"),
              );
              // form.submit();
            } else {
              // form.submit();
            }
          });
        }

        const buttonNextStack = event.target.querySelectorAll(
          "[data-direction-next]",
        );

        for (let j = 0; j < buttonNextStack.length; j++) {
          buttonNextStack[j].addEventListener("click", function () {
            // overwrite error class to not see any error message
            // event.detail.settings.fieldClass = "";

            // disable custom event handling
            // event.detail.settings.emitEvents = false;

            const hiddenField = event.target.querySelectorAll(
              "[data-input-hidden-current-page]",
            );

            if (hiddenField.length > 0) {
              // The field with the error
              hiddenField[0].setAttribute(
                "value",
                buttonNextStack[j].getAttribute("value"),
              );
            }
          });
        }
      },
      false,
    );

    /**
     * initialize multiple forms
     *
     * @type {NodeListOf<Element>}
     */
    const formStack = document.querySelectorAll("[data-form-validation]");
    const bouncerStack = [];
    for (let i = 0; i < formStack.length; i++) {
      bouncerStack[i] = new Bouncer(`#${formStack[i].id}`, {
        messageAfterField: false,
        disableSubmit: false,
        emitEvents: true,
      });

      const buttonPrevStack = formStack[i].querySelectorAll(
        "[data-direction-prev]",
      );
      for (let j = 0; j < buttonPrevStack.length; j++) {
        buttonPrevStack[j].addEventListener("click", function () {
          formStack[i].submit();
        });
      }
    }

    /**
     * initialize callbacks on form fields
     */
    document.addEventListener("input", checkValue);

    // copy button value for currentPage into hidden field before submit
    document.addEventListener(
      "bouncerFormValid",
      (event) => {
        const element = document.querySelectorAll(
          "[data-invalid-form-message]",
        );
        if (element.length > 0) {
          element[0].classList.remove(
            element[0].dataset.invalidFormMessageIsVisible,
          );
        }

        const form = event.target;
        form.submit();
      },
      false,
    );

    /**
     * Add or Remove Classes for empty or filled Elements
     *
     * @param e
     */
    function checkValue(e) {
      if (e.target.value == "" || e.target.value == " ") {
        e.target.classList.remove("form-element--has-value");
      } else {
        if (e.target.id == "complaintForm-152-batch_number") {
          let str = e.target.value;
          let re = /[Ll]{1}\s?\d{3}\s?\d{3}$/;
          let found = str.match(re);

          if (found === null) {
            e.target.classList.add("form-element--has-value");
            e.target.parentElement.classList.add("error");
          } else {
            e.target.classList.add("form-element--has-value");
            e.target.parentElement.classList.remove("error");
          }
        } else {
          e.target.classList.add("form-element--has-value");
        }
      }

      // remove error class on parent elements
      e.target.classList.remove("error");

      // different handles for different types
      switch (e.target.type.toLowerCase()) {
        case "radio":
        case "checkbox":
          var parentContainer = document.querySelectorAll(
            `[data-${e.target.dataset.groupParent}]`,
          );
          if (parentContainer.length > 0) {
            if (parentContainer[0].children.length > 0) {
              for (let i = 0; i < parentContainer[0].children.length; i++) {
                parentContainer[0].children[i].classList.remove("error");
              }
            }
          }
          break;
        default:
          break;
      }
    }
  })()
);

/**
 * Expand Bouncer Events
 */
document.addEventListener(
  "bouncerShowError",
  (event) => {
    // The field with the error
    const field = event.target;
    const parent = field.parentElement;

    parent.classList.add("error");

    // show global error message
    const element = document.querySelectorAll("[data-invalid-form-message]");
    if (element.length > 0) {
      element[0].classList.add(element[0].dataset.invalidFormMessageIsVisible);
    }
  },
  false,
);

document.addEventListener(
  "bouncerRemoveError",
  (event) => {
    // The field with the error
    const field = event.target;
    const parent = field.parentElement;

    parent.classList.remove("error");

    // remove error message
    const element = document.querySelectorAll("[data-invalid-form-message]");
    if (element.length > 0) {
      element[0].classList.remove(
        element[0].dataset.invalidFormMessageIsVisible,
      );
    }
  },
  false,
);
