(function delay() {
  const delayButton = document.querySelectorAll(".news-list__category");
  // eslint-disable-next-line func-names,no-use-before-define
  delayButton.forEach(clickDelay);

  function clickDelay(item) {
    const lottiePlayer = item.querySelector(".lottie-player");

    item.addEventListener("click", (e) => {
      e.preventDefault();
      lottiePlayer.setAttribute("direction", 1);
      lottiePlayer.setAttribute("autoplay", "");
      lottiePlayer.play();

      lottiePlayer.addEventListener("complete", () => {
        lottiePlayer.stop();
        lottiePlayer.seek(0);

        setTimeout(() => (window.location = item.getAttribute("href")), 0);
      });
    });
  }
})();
