(function flensNavigation() {
  const body = document.querySelector("body");
  const burgerMenu = document.querySelector("[data-burger-menu-container]");
  const burgerMenuBloseBtn = document.querySelector("[data-burger-menu-close]");
  const navigation = document.querySelector("[data-navigation-wrapper]");
  const navigationSubLevelLabels = document.querySelectorAll(
    "[data-sub-level-label]",
  );
  const subLevelAmount = navigationSubLevelLabels.length;
  const navigationSubLevel = "[data-sub-level]";
  const navigationSubLevelBack = document.querySelectorAll(
    "[data-sub-level-back]",
  );
  const CLASSOPENNAV = "nav--open";
  const CLASSOPENSUBNAV = "level--open";
  const CLASSNOSCROLL = "no--scroll";
  const languageButton = document.querySelector(
    "[data-current-language-select]",
  );
  const { parentElement } = languageButton ?? {};
  const OPENCLASS = "is--open";

  function toggleMenu(e) {
    e.preventDefault();

    if (navigation.classList.contains(CLASSOPENNAV)) {
      navigation.classList.remove(CLASSOPENNAV);
      body.classList.remove(CLASSNOSCROLL);
    } else {
      navigation.classList.add(CLASSOPENNAV);
      body.classList.add(CLASSNOSCROLL);
    }

    if (parentElement?.classList.contains(OPENCLASS)) {
      parentElement.classList.remove(OPENCLASS);
    }
  }

  burgerMenu.addEventListener("click", toggleMenu);
  burgerMenuBloseBtn?.addEventListener("click", toggleMenu);

  function toggleSubLevel(e) {
    e.preventDefault();
    const parentSubLevel = this.closest(navigationSubLevel);
    if (parentSubLevel.classList.contains(CLASSOPENSUBNAV)) {
      parentSubLevel.classList.remove(CLASSOPENSUBNAV);
    } else {
      parentSubLevel.classList.add(CLASSOPENSUBNAV);
    }
  }

  for (let i = 0; i < subLevelAmount; i++) {
    navigationSubLevelLabels[i].addEventListener("click", toggleSubLevel);
    navigationSubLevelBack[i].addEventListener("click", toggleSubLevel);
  }
})();
