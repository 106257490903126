/**
 * Adjust different heights on a group of elements
 * To adjust height of a group of elements each element needs: data-adjust-height-identifier="xyz"
 */
(function adjustHeight() {
  /**
   * new calculations on resize
   */
  adjust();
  window.addEventListener("resize", adjust);

  function adjust() {
    // Find all elements with the 'adjust-height' option
    const adjustHeightStack = document.querySelectorAll(
      "[data-adjust-height-identifier]",
    );
    const adjustHeightGroup = [];

    if (adjustHeightStack.length > 0) {
      // cycle all found elements
      for (var i = 0; i < adjustHeightStack.length; i++) {
        // get the identifier for the group of elements where to adjust height and save them
        adjustHeightStack[i].style.height = null;
        const adjustHeightIdentifier =
          adjustHeightStack[i].dataset.adjustHeightIdentifier;
        adjustHeightGroup[adjustHeightIdentifier] = adjustHeightIdentifier;
      }

      // @todo need a better solution then just the document width as an indicator
      // @todo *maybe* different breakpoints on different elements. add the clientWidth to the identifier and evaluate it here
      if (document.body.clientWidth >= 768) {
        // find the grouped elements by identifier
        let identifier;
        for (identifier in adjustHeightGroup) {
          const adjustHeightGroupElementStack = document.querySelectorAll(
            '[data-adjust-height-identifier="' + identifier + '"]',
          );

          // @todo need a better spultion then 2 cycles
          let highestValue = 0;
          for (var j = 0; j < adjustHeightGroupElementStack.length; j++) {
            highestValue =
              adjustHeightGroupElementStack[j].clientHeight > highestValue
                ? adjustHeightGroupElementStack[j].clientHeight
                : highestValue;
          }
          for (var k = 0; k < adjustHeightGroupElementStack.length; k++) {
            adjustHeightGroupElementStack[k].style.height = highestValue + "px";
          }
        }
      }
    }
  }
})();
