(function customSelectRetoure() {
  const dynamicCustomSelects = document.querySelectorAll(
    "[data-dynamic-custom-select]",
  );

  Array.prototype.forEach.call(
    dynamicCustomSelects,
    (dynamicCustomSelectContainer) => {
      const jsonString = dynamicCustomSelectContainer.querySelectorAll(
        "[data-trading-unit-stock]",
      );

      if (jsonString.length <= 0) {
        console.log("no JSON found for retoure select boxes");
        return false;
      }

      const jsonObject = JSON.parse(jsonString[0].dataset.tradingUnitStock);
      const selectSource = dynamicCustomSelectContainer.querySelectorAll(
        "[data-form__select-custom-src]",
      );
      const selectDestination = dynamicCustomSelectContainer.querySelectorAll(
        "[data-form__select-custom-dest]",
      );
      const defaultValue = selectDestination[0].children[1].innerHTML;
      const retoureEmptyText =
        selectSource[0].dataset.form__selectRetoureEmptyText; // dynamicCustomSelectContainer.querySelectorAll('[data-form__select-retoure-empty-text]');

      const selectItemsInner = selectSource[0].querySelectorAll(
        ".select-items-inner",
      );

      Array.prototype.forEach.call(selectItemsInner, (selectedItem) => {
        selectedItem.addEventListener(
          "click",
          function () {
            const selectedValue = this.dataset.value;
            const sourceOriginal =
              selectDestination[0].getElementsByTagName("select")[0];
            const sourceGeneratedSelected = selectDestination[0].children[1];
            const sourceGeneratedOptions = selectDestination[0].children[2];
            const sourceGeneratedText = selectDestination[0].children[1];

            // set default value text
            sourceGeneratedSelected.innerHTML = defaultValue;

            // remove options from generated select
            sourceGeneratedOptions.innerHTML = "";

            // remove options from original select
            sourceOriginal.innerHTML = "";

            // add options when available
            if (jsonObject[selectedValue]) {
              // add options to original select
              for (const [key2, value2] of Object.entries(
                jsonObject[selectedValue],
              )) {
                // create original Source Options
                const newChild = document.createElement("OPTION");
                newChild.innerHTML = value2;
                newChild.setAttribute("value", key2);
                sourceOriginal.appendChild(newChild);
              }

              // add options to generated select
              for (const [key3, value3] of Object.entries(
                jsonObject[selectedValue],
              )) {
                // create generated Source Options
                const newChild = document.createElement("DIV");
                newChild.innerHTML = value3;
                newChild.setAttribute("data-value", key3);
                newChild.classList.add("select-items-inner");
                sourceGeneratedOptions.appendChild(newChild);

                newChild.addEventListener("click", function () {
                  /* When an item is clicked, update the original select box, and the selected item: */
                  let y;
                  let i;
                  let k;
                  let s;
                  let h;
                  s =
                    this.parentNode.parentNode.getElementsByTagName(
                      "select",
                    )[0];
                  h = this.parentNode.previousSibling;
                  for (i = 0; i < s.length; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                      s.selectedIndex = i;
                      h.innerHTML = this.innerHTML;
                      y = this.parentNode.getElementsByClassName(
                        "form-element__select-custom--is-selected",
                      );
                      for (k = 0; k < y.length; k++) {
                        y[k].classList.remove(
                          "form-element__select-custom--is-selected",
                        );
                      }
                      this.setAttribute(
                        "class",
                        "select-items-inner form-element__select-custom--is-selected",
                      );
                      break;
                    }
                  }
                  h.click();
                });
              }

              // make options select clickable
              selectDestination[0].classList.remove("is--locked");
            } else {
              // create original Source Options
              const newChild = document.createElement("OPTION");
              newChild.innerHTML = "---";
              newChild.setAttribute("value", retoureEmptyText);
              sourceOriginal.appendChild(newChild);

              // create generated Source Options
              const newGeneratedChild = document.createElement("DIV");
              newGeneratedChild.innerHTML = "---";
              newGeneratedChild.setAttribute("data-value", retoureEmptyText);
              newGeneratedChild.classList.add("select-items-inner");
              sourceGeneratedOptions.appendChild(newGeneratedChild);

              // generate value text for empty select box
              sourceGeneratedText.innerHTML = retoureEmptyText;
            }
          },
          false,
        );
      });
    },
  );
})();
