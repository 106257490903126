(function calculateIngredients() {
  if (document.querySelectorAll("[data-original-value]").length === 0) {
    return;
  }

  // variables for ingredients
  const countDownBtn = document.querySelector("[data-count-down]");
  const countUpBtn = document.querySelector("[data-count-up]");
  const countPortionText = document.querySelector("[data-count-text]");
  const listItems = document.querySelectorAll("[data-original-value]");

  const countMultiplier = document.querySelector("[data-count-number]");
  const originalCountMultiplier = document.querySelector("[data-count-number]");
  originalCountMultiplier.dataset.countNumber = parseInt(
    countMultiplier.innerHTML,
    10,
  );
  const amountListItems = listItems.length;

  // variables for nutritions
  const listItemsNutrition = document.querySelectorAll(
    "[data-nutrition-value]",
  );
  const amountListItemsNutrition = listItemsNutrition.length;

  function replaceValue(value) {
    let valueString = value.toString();
    let firstValueString;
    let lastValue;

    if (valueString.indexOf(".") !== -1) {
      const dezimalArray = valueString.split(".");
      firstValueString = dezimalArray[0].toString();
      lastValue = dezimalArray[1].toString();

      if (dezimalArray[1].length > 3) {
        dezimalArray[1] = dezimalArray[1].substring(0, 3);
        // eslint-disable-next-line prefer-destructuring
        lastValue = dezimalArray[1];
      }

      dezimalArray[1] = `0.${dezimalArray[1]}`;
      valueString = dezimalArray[1].toString();
    }

    if (valueString === "0.25") {
      // 1/4
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#188;";
      }
      return "&#188;";
    }
    if (valueString === "0.5") {
      // 1/2
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#189;";
      }
      return "&#189;";
    }
    if (valueString === "0.75") {
      // 3/4
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#190;";
      }
      return "&#190;";
    }
    if (valueString === "0.143") {
      // 1/7
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8528;";
      }
      return "&#8528;";
    }
    if (valueString === "0.111") {
      // 1/9
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8529;";
      }
      return "&#8529;";
    }
    if (valueString === "0.1") {
      // 1/10
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8530;";
      }
      return "&#8530;";
    }
    if (valueString === "0.333") {
      // 1/3
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8531;";
      }
      return "&#8531;";
    }
    if (valueString === "0.666") {
      // 2/3
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8532;";
      }
      return "&#8532;";
    }
    if (valueString === "0.2") {
      // 1/5
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8533;";
      }
      return "&#8533;";
    }
    if (valueString === "0.4") {
      // 2/5
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8534;";
      }
      return "&#8534;";
    }
    if (valueString === "0.6") {
      // 3/5
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8535;";
      }
      return "&#8535;";
    }
    if (valueString === "0.8") {
      // 4/5
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8536;";
      }
      return "&#8536;";
    }
    if (valueString === "0.166") {
      // 1/6
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8537;";
      }
      return "&#8537;";
    }
    if (valueString === "0.833") {
      // 5/6
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8538;";
      }
      return "&#8538;";
    }
    if (valueString === "0.125") {
      // 1/8
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8539;";
      }
      return "&#8539;";
    }
    if (valueString === "0.375") {
      // 3/8
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8540;";
      }
      return "&#8540;";
    }
    if (valueString === "0.625") {
      // 5/8
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8541;";
      }
      return "&#8541;";
    }
    if (valueString === "0.875") {
      // 7/8
      if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
        // eslint-disable-next-line no-useless-concat
        return `${firstValueString} ` + "&#8542;";
      }
      return "&#8542;";
    }
    if (typeof firstValueString !== "undefined" && firstValueString !== "0") {
      if (lastValue.length > 2) {
        lastValue = lastValue.substring(0, 2);
      }

      // eslint-disable-next-line no-useless-concat
      if (lastValue === "0" || lastValue === "00" || lastValue === "000") {
        return `${firstValueString}`;
      }

      return `${firstValueString}.${lastValue}`;
    }

    return valueString;
  }

  function setValue(i) {
    const { originalValue } = listItems[i].dataset;
    listItems[i].innerHTML = replaceValue(originalValue);

    if (listItemsNutrition[i]) {
      const originalCountNutrition = listItemsNutrition[i].getAttribute(
        "data-nutrition-value",
      );
      const countMultiplierNumber = parseInt(countMultiplier.innerHTML, 10);
      const newNutrition =
        parseInt(originalCountNutrition, 10) * countMultiplierNumber;
      listItemsNutrition[i].innerHTML = replaceValue(newNutrition);
    }
  }

  function updatePortionText() {
    // eslint-disable-next-line no-shadow
    const countMultiplierNumber = parseInt(countMultiplier.innerHTML, 10);

    if (countMultiplierNumber === 1) {
      if (countPortionText.classList.contains("is--single")) {
        return;
      }

      countPortionText.classList.add("is--single");
      countDownBtn.classList.add("is--disabled");
    } else if (countPortionText.classList.contains("is--single")) {
      countPortionText.classList.remove("is--single");
      countDownBtn.classList.remove("is--disabled");
    }
  }

  function updateMultiplier(number) {
    // eslint-disable-next-line no-shadow
    const countMultiplierNumber = parseInt(countMultiplier.innerHTML, 10);

    if (number >= 1) {
      countMultiplier.innerHTML = countMultiplierNumber + number;
    } else {
      countMultiplier.innerHTML = countMultiplierNumber - 1;
    }

    updatePortionText();
  }

  function countUp(e) {
    e.preventDefault();

    if (countUpBtn.classList.contains("is--disabled")) {
      countUpBtn.classList.remove("is--disabled");
    }
    const countMultiplierNumber = parseInt(countMultiplier.innerHTML, 10);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < amountListItems; i++) {
      const originalCount = listItems[i].getAttribute("data-original-value");
      // eslint-disable-next-line max-len
      const newCount =
        (originalCount / countMultiplier.dataset.countNumber) *
        (countMultiplierNumber + 1);

      listItems[i].innerHTML = replaceValue(newCount);

      if (i === amountListItems - 1) {
        updateMultiplier(1);
      }
    }

    for (let ni = 0; ni < amountListItemsNutrition; ni++) {
      const originalCountNutrition = listItemsNutrition[ni].getAttribute(
        "data-nutrition-value",
      );
      // eslint-disable-next-line max-len
      const newNutrition = originalCountNutrition * (countMultiplierNumber + 1);
      listItemsNutrition[ni].innerHTML = replaceValue(newNutrition);
    }
  }

  function countDown(e) {
    e.preventDefault();
    const countMultiplierNumber = parseInt(countMultiplier.innerHTML, 10);

    if (countMultiplierNumber > 1) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < amountListItems; i++) {
        const originalCount = listItems[i].getAttribute("data-original-value");
        // eslint-disable-next-line max-len
        const newCount =
          (originalCount / countMultiplier.dataset.countNumber) *
          (countMultiplierNumber - 1);

        listItems[i].innerHTML = replaceValue(newCount);

        if (i === amountListItems - 1) {
          updateMultiplier(0);
        }
      }

      for (let ni = 0; ni < amountListItemsNutrition; ni++) {
        const originalCountNutrition = listItemsNutrition[ni].getAttribute(
          "data-nutrition-value",
        );
        // eslint-disable-next-line max-len
        const newNutrition =
          originalCountNutrition * (countMultiplierNumber - 1);

        listItemsNutrition[ni].innerHTML = replaceValue(newNutrition);
      }
    }
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < amountListItems; i++) {
    setValue(i);

    countDownBtn.addEventListener("click", countDown);
    countUpBtn.addEventListener("click", countUp);
  }

  for (let ni = 0; ni < amountListItemsNutrition; ni++) {
    const originalCountNutrition = listItemsNutrition[ni].getAttribute(
      "data-nutrition-value",
    );
    const countMultiplierNumber = parseInt(countMultiplier.innerHTML, 10);
    const newNutrition =
      parseInt(originalCountNutrition, 10) * countMultiplierNumber;
    listItemsNutrition[ni].innerHTML = replaceValue(newNutrition);
  }
})();
