// import Swiper JS
import Swiper from "swiper";

new Swiper(".image-slider", {
  loop: true,

  // If we need pagination
  pagination: {
    el: ".image-slider__pagination",
    type: "fraction",
  },

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
