import Swiper from "swiper";
// eslint-disable-next-line import/no-unresolved -- is resolved correctly...
import "swiper/css";
import { Navigation, Pagination } from "swiper/modules";
import { sliderConfigurations } from "./slider_configuration";

// error
if (sliderConfigurations.length === 0) {
  console.warn("Slider Configuration File missing...Check JS");
}

// initialize each slider type
for (const currentConfig of sliderConfigurations) {
  const { config, options, callbacks } = currentConfig;
  const containers = document.querySelectorAll(
    `[data-slider-identifier="${config.selector}"]`,
  );

  for (let j = 0; j < containers.length; j++) {
    const container = containers[j];
    container.setAttribute("data-slider-container-index", j);

    /** @type {HTMLElement} */
    const sliderElement = container.querySelector(
      `[data-slider-wrapper="${config.selector}"]`,
    );
    sliderElement.dataset.sliderIndex = `${j}`;

    let countSlideElements = 0;
    if (document.body.clientWidth >= 992) {
      countSlideElements =
        container.querySelectorAll(`.${config.sliderElementClass}`).length - 1;
    }

    const slider = new Swiper(sliderElement, {
      init: false,
      modules: [Navigation, Pagination],
      direction: "horizontal",
      initialSlide: countSlideElements,
      ...options,
      navigation: {
        prevEl: container.querySelector(options.prevButton),
        nextEl: container.querySelector(options.nextButton),
      },
    });

    const { init = [], slideChange = [], resize = [] } = callbacks;
    for (const name of init) {
      slider.on("init", getFunction(name, container, currentConfig, slider));
    }
    slider.init();

    for (const name of slideChange) {
      slider.on(
        "slideChangeTransitionEnd",
        getFunction(name, container, currentConfig, slider),
      );
    }
    for (const name of resize) {
      slider.on("resize", getFunction(name, container, currentConfig, slider));
    }
  }
}

/**
 * @typedef {'refreshAdditionalContent'|'initializeFilter'|'refreshFilterStatus'|'refreshSlider'} SliderFunctionName
 */

/**
 * @param {SliderFunctionName} name
 * @param {HTMLElement} container
 * @param {Object} config
 * @param {Swiper} slider
 * @returns {Function}
 */
function getFunction(name, container, config, slider) {
  const params = { container, config, slider };
  switch (name) {
    case "refreshAdditionalContent":
      return () => refreshAdditionalContent(params);
    case "initializeFilter":
      return () => initializeFilter(params);
    case "refreshFilterStatus":
      return () => refreshFilterStatus(params);
    case "refreshSlider":
      return () => refreshSlider(params);
    default:
      throw new Error(`Unexpected function name "${name}"`);
  }
}

/**
 * refresh Top/Bottom Content Parts equal to active slider index
 */
function refreshAdditionalContent({ container, config: { config }, slider }) {
  const additionalContent = container.querySelectorAll(
    `[data-slider-additional-content="${config.selector}"]`,
  );
  const { slidesPerView } = slider.params;
  for (const content of additionalContent) {
    const highestIndex = getRealNumberOfSlides(container, config);
    content
      .querySelectorAll("li")
      .forEach((item) =>
        item.classList.remove("slider-additional-content--element-active"),
      );

    // Beim Produkt Slider ist (ab Breakpoint LG) nicht der aktive Slide im Vordergrund,
    // sondern "Slide-Prev", deshalb muss auch der zugehörige Text anders ermittelt werden
    // Unter Breakpoint LG ist es beim Produkt Slider wieder eine 1:1 Relation von Bild und Text
    const increaseRealIndex =
      content.dataset.indexIsExactRelation !== "true" && slidesPerView > 1;
    const index = increaseRealIndex
      ? slider.realIndex + 1 > highestIndex
        ? 0
        : slider.realIndex + 1
      : slider.realIndex;
    content
      .querySelectorAll(`[data-index="${index}"]`)
      .forEach((element) =>
        element.classList.add("slider-additional-content--element-active"),
      );
  }
}

/**
 * Slider Filter
 *
 * @todo funktioniert nur für einen Slider mit Filter auf der Page. Reicht aktuell aber im Zweifel muss die Funktion hier erweitert werden. Die Filter Module haben bereits bei der Initialisierung einen Identifier erhalten
 */
function initializeFilter({ container, slider }) {
  // select all filter elements
  const filterElements = container.querySelectorAll(
    '[data-filter-element-identifier="slider-filter__element"]',
  );

  if (filterElements.length > 0) {
    const onLoadArray = window.location.pathname.split("/");

    if (onLoadArray[onLoadArray.length - 1] === "") {
      onLoadArray.pop();
    }
    if (onLoadArray.length === 4) {
      const activeTab = document.querySelector(
        `[data-product-url='${onLoadArray[3]}']`,
      );
      const category = activeTab.dataset.productCategory;
      const indices = Array.from(
        container.querySelectorAll(`[data-product-category='${category}']`),
      )
        .filter((item) => item.classList.contains("slider--element"))
        .map((item) => parseInt(item.dataset.swiperSlideIndex));
      slider.slideTo(Math.min(...indices) + 1);
    }
  }

  filterElements.forEach((filterElement) =>
    filterElement.addEventListener("click", () =>
      onFilterClick(slider, filterElement),
    ),
  );
}

function onFilterClick(slider, filterElement) {
  const { productUrl } = filterElement.dataset;
  const { pathname, protocol, host } = window.location;
  const pathArray = pathname.split("/");
  let secondLevelLocation = pathArray[2];
  const CATEGORY = "kategorie";
  const PRODUCTS = "produkte";

  if (secondLevelLocation === "") {
    window.location.href = `${protocol}//${host}/${pathArray[1]}/${CATEGORY}/${productUrl}/`;
  } else if (secondLevelLocation === CATEGORY) {
    window.location.href = `${protocol}//${host}/${pathArray[1]}/${pathArray[2]}/${productUrl}/`;
  } else {
    window.location.href = `${protocol}//${host}/${PRODUCTS}/${CATEGORY}/${productUrl}/`;
  }

  const productCategory = parseInt(filterElement.dataset.productCategory);
  const items = Array.from(slider.slides).filter(
    (item) => !item.classList.contains("swiper-slide-duplicate"),
  );
  let goToIndex = Math.min(
    ...items
      .filter(
        (item) => parseInt(item.dataset.productCategory) === productCategory,
      )
      .map((item) => parseInt(item.dataset.swiperSlideIndex)),
  );

  // you have to sub 1 because there are two active elements but only the second is focused
  if (document.body.clientWidth >= 992) {
    if (goToIndex > 0) {
      --goToIndex;
    } else {
      goToIndex = Math.max(
        ...items.map((item) => parseInt(item.dataset.swiperSlideIndex)),
      );
    }
  }

  if (slider.params.loop) {
    slider.slideToLoop(goToIndex);
  } else {
    slider.slideTo(goToIndex);
  }
}

/**
 * Slider Filter for prev/next Buttons
 *
 * @todo funktioniert nur für einen Slider mit Filter auf der Page. Reicht aktuell aber im Zweifel muss die Funktion hier erweitert werden. Die Filter Module haben bereits bei der Initialisierung einen Identifier erhalten
 */
function refreshFilterStatus({ container, slider }) {
  let productCategory = "";
  for (const slide of Array.from(slider.slides)) {
    if (document.body.clientWidth >= 992) {
      if (slide.classList.contains("swiper-slide-next")) {
        ({ productCategory } = slide.dataset);
      }
    } else if (slide.classList.contains("swiper-slide-active")) {
      ({ productCategory } = slide.dataset);
    }
  }
  container
    .querySelectorAll(
      '[data-slider-filter="product-slider"] *[data-filter-element-identifier="slider-filter__element"]',
    )
    .forEach((element) =>
      element.classList.toggle(
        "slider-filter__element--is-active",
        element.dataset.productCategory === productCategory,
      ),
    );
}

/** refresh slider on resize */
function refreshSlider({ slider, container, config: { config } }) {
  slider.update();
  const { slidesPerView } = slider.params;
  const highestIndex = getRealNumberOfSlides(container, config);

  // index des aktiven Slides
  let { realIndex } = slider;

  // Unter Breakpoint LG ist es beim Produkt Slider wieder eine 1:1 Relation von Bild und Text - die Logik ist hier umgedreht im Vergleich zu der Funktion refreshAdditionalContent
  const increaseRealIndex = slidesPerView <= 1;

  // ermittele den wirklich anzuzeigenden Slide
  realIndex = increaseRealIndex
    ? realIndex + 1 > highestIndex
      ? 0
      : realIndex + 1
    : realIndex;

  // TODO OMG...:
  // Bugfix: Es scheint ein Bug zu sein !?! Nach einem Resize wird bei slideTo nicht zu der angegebenen Position geslidet sondern zu der Position--
  realIndex = realIndex + 1 > highestIndex ? 0 : realIndex + 1;

  // gehe zu neu ermittelter Position
  slider.slideTo(realIndex);
}

/**
 * get the real number of defined slides
 */
function getRealNumberOfSlides(container, config) {
  return Math.max(
    ...Array.from(
      container.querySelectorAll(
        `[data-slider-additional-content="${config.selector}"]`,
      ),
    )
      .map((content) => Array.from(content.querySelectorAll("li")))
      .flat()
      .map((item) => parseInt(item.dataset.index)),
  );
}
