/* eslint-disable no-param-reassign, no-underscore-dangle */
/**
 * Get the map center in relation to the viewport.
 *
 * @function
 * @returns {number[]} Long and lat of the map center.
 */

/* global L */

function getMapCenter() {
  // Set map center with regards to the viewport
  const lg = window.matchMedia("(min-width: 992px)").matches;
  const xl = window.matchMedia("(min-width: 1200px)").matches;

  let mapCenter;

  if (xl) {
    mapCenter = [54.45, 9.6];
  } else if (lg) {
    mapCenter = [54.1, 9.5];
  } else {
    mapCenter = [54.55, 9.5];
  }

  return mapCenter;
}

/**
 * Store marker in local storage
 *
 * @function
 * @param {string} id - The marker id.
 */
function setLocalStorage(id) {
  localStorage.setItem("marker", id);
}

/**
 * Toggle the content in the map sidebar.
 *
 * @function
 * @param {object} item - The item data.
 */
function toggleAside(item) {
  const map = document.querySelector(".js-map");
  const aside = map.querySelector(".js-map__aside");

  const info = map.querySelector(".js-info");
  info.classList.remove("map__info--opaque");

  setTimeout(() => {
    // Get current date
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth();
    const year = now.getFullYear();
    const today = new Date(year, month, day);

    const logo = map.querySelector(".js-logo");
    logo.src = `/${item.logo}`;
    logo.alt = `Logo von ${item.name}`;

    const date = map.querySelector(".js-date");
    const event = new Date(item.date);

    const isExpired = event.valueOf() < today.valueOf();

    const options = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    date.innerHTML = event.toLocaleDateString("de-DE", options);

    const name = map.querySelector(".js-name");
    name.innerHTML = item.name;

    const url = map.querySelector(".js-url");
    url.href = `https://${item.contact.url}`;
    url.innerHTML = item.contact.url;

    const tel = map.querySelector(".js-tel");
    tel.innerHTML = item.contact.tel;

    const link = map.querySelector(".js-link");

    if (isExpired) {
      aside.classList.add("map__aside--is-expired");
      link.classList.add("button-map-map--is-expired");
      link.removeAttribute("download");
      link.removeAttribute("href");
    } else {
      aside.classList.remove("map__aside--is-expired");
      link.classList.remove("button-map-map--is-expired");
      link.setAttribute("download", "");
      link.setAttribute("href", item.link);
    }

    const note = map.querySelector(".js-note");

    if (item.note) {
      note.parentNode.style.display = "block";
      note.innerHTML = item.note;
    } else {
      note.parentNode.style.display = "none";
    }

    info.classList.add("map__info--opaque");
  }, 250);
}

/**
 * Toggle active state of markers.
 *
 * @function
 * @param {object} markers - All markers on the map.
 * @param {object[]} markers.marker - Clicked marker.
 * @param {object} markers.markers - References to all markers.
 */
function toggleMarkers({ marker, markers }) {
  // Get current date
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth();
  const year = now.getFullYear();
  const today = new Date(year, month, day);
  // Set marker images
  const active = "/static/images/marker-active.png";
  const inactive = "/static/images/marker.png";
  const deactivated = "/static/images/marker-deactivated.png";

  markers.forEach((m) => {
    const event = new Date(m.options.item.date);
    const isExpired = event.valueOf() < today.valueOf();

    if (m.options.item.id === marker.id) {
      m._icon.src = isExpired ? deactivated : active;
    } else {
      m._icon.src = isExpired ? deactivated : inactive;
    }
  });
}

/**
 * Handle click on a marker.
 *
 * @function
 * @param {Event} e - A click event.
 */
function handleClick(e) {
  const { options } = e.target;

  const { id } = options.item;
  const { item } = options;
  const marker = e.target._icon;
  const markers = Object.values(options.markers);

  setLocalStorage(id);
  toggleAside(item);
  toggleMarkers({ marker, markers });
}

/**
 * Add markers to map.
 *
 * @function
 * @param {object} markers - Map markers.
 * @param {object[]} markers.items - A list of literal event objects.
 * @param {object} markers.map - A map object.
 */
function addMarkers({ items, map }) {
  // Customized markers
  const flensIcon = L.icon({
    // eslint-disable-line no-undef
    iconUrl: "/static/images/marker.png",
    iconSize: [28, 28],
    iconAnchor: [14, 14],
  });

  // Get current date
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth();
  const year = now.getFullYear();
  const today = new Date(year, month, day);

  return new Promise((resolve, reject) => {
    // References to all markers
    const markers = {};

    try {
      // Add markers to map
      items.forEach((item) => {
        const event = new Date(item.date);
        const isExpired = event.valueOf() < today.valueOf();

        markers[item.id] = L.marker(item.marker, {
          // eslint-disable-line no-undef
          item,
          icon: flensIcon,
          markers,
          zIndexOffset: isExpired ? 0 : 5000,
        })
          .addTo(map)
          .on("click", handleClick);

        markers[item.id]._icon.id = item.id;
      });

      resolve(markers);
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * Activate a marker.
 *
 * @function
 * @param {object} marker - A map marker.
 * @param {object[]} marker.items - A list of literal event objects.
 * @param {object} marker.markers - References to all markers.
 */
function activateMarker({ items, markers }) {
  // Check if there is a marker in local storage
  const markerInLocalStorage = localStorage.getItem("marker");

  if (markerInLocalStorage) {
    const marker = markers[markerInLocalStorage]._icon;

    toggleMarkers({ marker, markers: Object.values(markers) });

    const [item] = items.filter(
      (i) => i.id.toString() === markerInLocalStorage,
    );

    toggleAside(item);
  } else {
    // Activate first item
    const [id, marker] = Object.entries(markers)[0];
    const { item } = marker.options;

    toggleMarkers({
      marker: marker._icon,
      markers: Object.values(markers),
    });

    toggleAside(item);

    // Write active marker into local storage
    localStorage.setItem("marker", id);
  }
}

/**
 * Initialize the map.
 *
 * @function
 * @param {object} map - A map with events.
 * @param {string} map.anchor - The dom anchor for the map.
 * @param {number[]} map.center - Long and lat to mark the map center.
 * @returns {object} map - A map object.
 */
function initMap({ anchor, center }) {
  // Initialize Leaflet
  const map = L.map(anchor, {
    // eslint-disable-line no-undef
    center,
    zoom: 9,
    zoomControl: false,
  });

  // Add the OpenStreetMap tiles
  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    // eslint-disable-line no-undef
    attribution:
      '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
  }).addTo(map);

  return map;
}

/**
 * Set-up an event map.
 *
 * @function
 * @param {object} map - A map with events.
 * @param {string} map.anchor - The dom anchor for the map.
 * @param {object[]} map.items - A list of literal event objects.
 */
export default function setupMap({ anchor, items }) {
  localStorage.removeItem("items");
  const center = getMapCenter();
  const map = initMap({ anchor, center });

  addMarkers({ items, map })
    .then((markers) => activateMarker({ items, markers }))
    .catch((reason) => {
      console.error("Error adding markers: ", reason);
    });
}
