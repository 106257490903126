/**
 * Add event items.
 *
 * @function
 * @param {object} listItem - A list item with event data.
 * @param {object[]} listItem.items - A list of literal event objects.
 * @param {HTMLUListElement} listItem.list - The dom node for the event items.
 * @param {boolean} listItem.tooSmall - If the viewport is smaller than
 *                                      992px.
 */
function addEventItems({ items, list, tooSmall }) {
  // Instantiate template
  const template = document.getElementById("eventitem");

  // Get current date
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth();
  const year = now.getFullYear();
  const today = new Date(year, month, day);

  items.forEach((item, index) => {
    // Clone template and insert it into list
    const clone = template.content.cloneNode(true);

    // Add class if there are more than 4 items and if the viewport is
    // smaller than 992px
    if (index > 3 && tooSmall) {
      const i = clone.querySelector(".js-item");

      i.classList.add("list__item--hidden");
    }

    // Add content into the list item
    const logo = clone.querySelector(".js-logo");
    logo.src = `/${item.logo}`;
    logo.alt = `Logo von ${item.name}`;

    const date = clone.querySelector(".js-date");
    const event = new Date(item.date);

    const isExpired = event.valueOf() < today.valueOf();

    const options = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    date.innerHTML = event.toLocaleDateString("de-DE", options);

    const name = clone.querySelector(".js-name");
    name.innerHTML = item.name;

    const link = clone.querySelector(".js-link");
    link.setAttribute("href", item.link);

    if (isExpired) {
      link.classList.add("list__link--is-expired");
      link.removeAttribute("download");
      link.setAttribute("href", "");
    } else {
      link.classList.remove("list__link--is-expired");
      link.setAttribute("download", "");
      link.setAttribute("href", item.link);
    }

    list.appendChild(clone);
  });
}

/**
 * Add show all events button.
 *
 * @function
 * @param {object} button - A button to show all events.
 * @param {HTMLUListElement} button.list - The dom node for the event items.
 * @param {boolean} button.tooMany - If there are more than N items.
 * @param {boolean} button.tooSmall - If the viewport is smaller than
 *                                    992px.
 */
function addEventToggle({ list, tooMany, tooSmall }) {
  if (tooMany && tooSmall) {
    const handleShowAll = (e) => {
      // Get all hidden items and the last row
      const { hiddenItems, lastRow } = e.detail;

      // Show all hidden items
      hiddenItems.forEach((item) => {
        item.classList.remove("list__item--hidden");
      });

      // Hide last row
      lastRow.style.display = "none";

      // Remove event listener
      // eslint-disable-next-line no-use-before-define
      removeShowAll();
    };

    const removeShowAll = () => {
      list.removeEventListener("showAllEvents", handleShowAll);
    };

    // Add event listener to show all events
    list.addEventListener("showAllEvents", handleShowAll);

    // Get all hidden items
    const hiddenItems = list.querySelectorAll(".list__item--hidden");

    // Instantiate template
    const template = document.getElementById("eventstoggle");

    // Clone template and insert it into list
    const clone = template.content.cloneNode(true);

    const button = clone.querySelector(".js-button");

    button.addEventListener("click", (e) => {
      e.preventDefault();

      // Dispatch show all events
      e.target.dispatchEvent(
        new CustomEvent("showAllEvents", {
          bubbles: true,
          composed: true,
          detail: {
            hiddenItems,
            lastRow: button.closest("li"),
          },
        }),
      );
    });

    list.appendChild(clone);
  }
}

/**
 * Set-up the list items.
 *
 * @function
 * @param {object[]} items - A list of literal event objects.
 */
export default function setupList(items) {
  // Get the list node
  const list = document.querySelector(".js-list");

  // Test if template is supported
  if ("content" in document.createElement("template")) {
    // Check if there are too many events
    const tooMany = items.length > 4;

    // Check if the viewport is smaller than 992px
    const tooSmall = window.matchMedia("(max-width: 991px)").matches;

    // Add event items
    addEventItems({ items, list, tooSmall });

    // Add event toggle
    addEventToggle({ list, tooMany, tooSmall });
  } else {
    // Hide the list node if it can't be populated.
    list.style.display = "none";
  }
}
