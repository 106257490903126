(function regiondoReviewCookieCheck() {
  const regiondoIframe = document.querySelector("#regiondo-review-widget");
  if (document.querySelector("#regiondo-review-widget") === null) {
    return;
  }

  const parentSection = regiondoIframe.closest("section");

  function showHideSection() {
    // eslint-disable-next-line no-undef
    if (CookieConsent.consent.preferences === false) {
      parentSection.style.display = "none";
    } else if (
      parentSection.getAttribute("style") !== null ||
      parentSection.getAttribute("style") !== ""
    ) {
      parentSection.removeAttribute("style");
    }
  }

  window.addEventListener("CookiebotOnLoad", () => showHideSection(), false);
})();
