const header = document.querySelector(".header");

if (header) {
  document.addEventListener("DOMContentLoaded", init);
}

const OPEN_CLASS = "is--open";
const IS_SCROLLED_CLASS = "page--is-scrolled";
const SCROLL_CLASS = "background-color--corporate-primary";

function init() {
  const headerHeight = header.offsetHeight;
  const navigation = document.querySelector(".js-navigation");
  if (!navigation) {
    return false;
  }

  const originalNavigationClassList = getClassByPrefix(
    navigation,
    "background",
  );

  const heroContainer = document.querySelector(".js-hero");
  const languageButton = document.querySelector(
    "[data-current-language-select]",
  );
  const list = document.querySelector(".nav--second");
  const navItems = Array.from(list?.children ?? []);

  let pageHasHero = !!heroContainer;
  let heroHeight = heroContainer?.offsetHeight ?? 0;
  let lastScrollPosition = 0;
  let timerId;

  function closeDropDown() {
    const { parentElement } = languageButton ?? {};
    if (parentElement?.classList.contains(OPEN_CLASS)) {
      parentElement.classList.remove(OPEN_CLASS);
    }
  }

  function onScroll() {
    if (!timerId) {
      timerId = setTimeout(onScrollDebounced, 300);
    }
  }

  // execute scroll navigation
  function onScrollDebounced() {
    timerId = undefined;
    const { scrollY } = window;

    // scrolling up: show navigation
    if (scrollY < lastScrollPosition) {
      header.classList.remove(IS_SCROLLED_CLASS);
      toggleScrollClasses(
        navigation,
        originalNavigationClassList,
        SCROLL_CLASS,
        scrollY > heroHeight,
      );

      // Megamenu hover activate
      navItems.forEach((item) => (item.style.pointerEvents = "auto"));
    } else if (scrollY >= headerHeight) {
      header.classList.add(IS_SCROLLED_CLASS);
      navItems.forEach((item) => (item.style.pointerEvents = "none"));
      closeDropDown();

      if (!pageHasHero) {
        toggleScrollClasses(
          navigation,
          originalNavigationClassList,
          SCROLL_CLASS,
        );
      }
    }

    lastScrollPosition = scrollY;
  }

  function toggleScrollClasses(
    navigation,
    originalClasses,
    scrollClass,
    isScrolled = true,
  ) {
    for (let i = 0; i < originalNavigationClassList.length; i++) {
      navigation.classList.toggle(originalNavigationClassList[i], !isScrolled);
    }
    navigation.classList.toggle(scrollClass, isScrolled);
  }

  // get original background color class
  function getClassByPrefix(node, prefix) {
    const regx = new RegExp(`\\b${prefix}[^ ]* ?\\b`, "g");
    for (const item of node.classList) {
      const result = item.match(regx);
      if (result) {
        return item.match(regx);
      }
    }
    return [];
  }

  // event listener
  window.addEventListener("scroll", onScroll, false);
}
