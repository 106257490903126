// eslint-disable-next-line import/prefer-default-export
export function counter() {
  /**
   * Count in steps to number.
   *
   * @param {number} num - Number to count to
   * @param {HTMLElement} element - Element to update
   * @param {number} count - Initial count
   * @param {number} steps - Steps to take
   * @param {number} duration - Duration per interval
   * @param {string} locale - Locale to use
   */

  const countTo = ({
    element,
    count = 0,
    steps = 500,
    duration = 25,
    locale = "de-DE",
  }) => {
    const speed = parseInt(element.dataset.counterSpeed, 10);

    const num = parseFloat(element.dataset.counterMax);

    if (speed !== "") {
      if (speed !== 0) {
        // eslint-disable-next-line no-param-reassign
        steps = speed / duration;
      } else {
        // eslint-disable-next-line no-param-reassign
        steps = 500;
      }
    }

    if (!Number.isNaN(num)) {
      const inc = num / steps;

      // Check if num is a long number
      if ((num > 9999 && !Number.isInteger(num)) || num > 99999) {
        element.classList.add("counter__number--longnum");
      }

      const int = setInterval(() => {
        if (count <= num) {
          // Reduce count to two digits after the decimal point
          // const countFixed = Number(count.toFixed(2));
          let countFixed;

          // Fix the decimals
          if (Number.isInteger(num)) {
            countFixed = Number(count.toFixed(0));
          } else {
            const [, decimals] = num.toString().split(".");

            if (decimals.length > 1) {
              countFixed = Number(count.toFixed(2));
            } else {
              countFixed = Number(count.toFixed(1));
            }
          }

          // Format number by locale
          const countLocal = countFixed.toLocaleString(locale);

          // Update element with new count
          element.innerHTML = countLocal;

          // Increment count
          count += inc;
        } else {
          // Clear interval if count reaches num
          clearInterval(int);

          // Make sure that the last num is exactly the num to be
          element.innerHTML = num.toLocaleString(locale);
        }
      }, duration);
    }
  };

  // Element to update
  const elements = document.querySelectorAll(".js-counter__number");

  // Check if there are any elements
  if (elements.length) {
    // Iterate over elements
    elements.forEach((element) => {
      if (
        element.hasAttribute("data-counter-max") &&
        !element.hasAttribute("data-start-time")
      ) {
        countTo({ element });
      }
    });
  }
}
