export var sliderConfigurations = [
  {
    config: {
      name: "Chronik Slider",
      description: "",
      id: "chronik-slider",
      selector: "chronik-slider",
      prevButtonClass: "chronik-slider--prev",
      nextButtonClass: "chronik-slider--next",
      sliderElementClass: "slider--element",
    },
    options: {
      slidesPerView: 1,
      initialSlide: 0,
      autoplay: false,
      prevButton:
        '[data-slider-button-prev-identifier="chronik-slider-button-prev"]',
      nextButton:
        '[data-slider-button-next-identifier="chronik-slider-button-next"]',
      speed: 500,
      loop: true,
      centered: false,
      breakpoints: {
        992: {
          slidesPerView: 1,
        },
      },
    },
    callbacks: {
      slideChange: ["refreshAdditionalContent"],
    },
  },
  {
    config: {
      name: "Produkt Slider",
      description: "",
      id: "product-slider",
      selector: "product-slider",
      prevButtonClass: "product-slider--prev",
      nextButtonClass: "product-slider--next",
      sliderElementClass: "slider--element",
    },
    options: {
      slidesPerView: 1,
      // initialSlide: false,
      autoplay: false,
      prevButton:
        '[data-slider-button-prev-identifier="product-slider-button-prev"]',
      nextButton:
        '[data-slider-button-next-identifier="product-slider-button-next"]',
      speed: 500,
      loop: true,
      centered: false,
      centerInsufficientSlides: true,
      mousewheel: {
        forceToAxis: true,
      },
      breakpoints: {
        992: {
          slidesPerView: 2,
        },
      },
    },
    callbacks: {
      slideChange: ["refreshAdditionalContent", "refreshFilterStatus"],
      init: [
        "refreshAdditionalContent",
        "initializeFilter",
        "refreshFilterStatus",
      ],
    },
  },
];
