(function flensfinder() {
  const flensfinderContainer = document.querySelectorAll(
    "[data-flensfinder-container]",
  );

  if (flensfinderContainer.length > 0) {
    /**
     * filter
     */
    const filterButton = flensfinderContainer[0].querySelectorAll(
      "[data-filter-button]",
    );
    const filterContainer = flensfinderContainer[0].querySelectorAll(
      "[data-filter-result-container]",
    );

    if (filterButton.length > 0) {
      filterButton[0].addEventListener("click", function () {
        this.classList.toggle("filter--is-open");
        filterContainer[0].classList.toggle("filter--is-open");
      });
    }

    /**
     * load more
     */
    const loadMoreResultList = flensfinderContainer[0].querySelectorAll(
      "[data-load-more-result-list]",
    );
    const loadMoreButton = flensfinderContainer[0].querySelectorAll(
      "[data-load-more-button]",
    );
    const loadMoreImage = flensfinderContainer[0].querySelectorAll(
      "[data-load-more-image]",
    );
    const loadMoreError =
      flensfinderContainer[0].querySelectorAll("[data-load-error]");
    const url = flensfinderContainer[0].querySelectorAll(
      "[data-load-more-url]",
    );
    const xhr = new XMLHttpRequest();

    if (loadMoreButton.length > 0) {
      loadMoreButton[0].addEventListener("click", function () {
        loadMoreButton[0].style.display = "none";
        loadMoreImage[0].style.display = "block";

        xhr.open("GET", url[0].value, true);
        xhr.responseType = "json";

        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              loadMoreResultList[0].innerHTML += xhr.response.html;
              if (xhr.response.more === true) {
                loadMoreButton[0].style.display = "table";
              }
              loadMoreImage[0].style.display = "none";
            }
            if (xhr.status >= 400) {
              console.log("Flensfinder JSON File not available.");
              loadMoreImage[0].style.display = "none";
              loadMoreError[0].style.display = "block";
              return false;
            }
          }
        };
        xhr.send();
      });
    }

    /**
     * close popup
     */
    const popupContainer = flensfinderContainer[0].querySelectorAll(
      "[data-popup-container]",
    );
    const popupClose =
      flensfinderContainer[0].querySelectorAll("[data-popup-close]");

    if (popupClose.length > 0) {
      popupClose[0].addEventListener("click", function () {
        popupContainer[0].classList.add("results-popup--is-remove");
        window.setTimeout(function () {
          popupContainer[0].remove();
        }, 250);
      });
    }
  }
})();
