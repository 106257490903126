(function scrollTop() {
  const scrollBtn = document.querySelector(".js-scroll-up-button");
  const VISIBLECLASS = "is-visible";

  document.addEventListener("scroll", () => {
    if (window.scrollY > 500) {
      scrollBtn.classList.add(VISIBLECLASS);
    } else {
      scrollBtn.classList.remove(VISIBLECLASS);
    }
  });

  scrollBtn.addEventListener("click", (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
})();
