(function videoDisclaimer() {
  window.addEventListener("load", function () {
    var containerStack = document.querySelectorAll("[data-video-embed]");

    for (var i = 0; i < containerStack.length; i++) {
      let container = containerStack[i];
      let playerContainer = container.querySelectorAll(
        "[data-video-embed-player]",
      );
      let buttonEl = container.querySelectorAll("[data-video-embed-button]");

      if (buttonEl.length > 0) {
        buttonEl[0].addEventListener("click", function () {
          let templateEl = container.querySelectorAll(
            "[data-video-embed-hidden-container]",
          );
          let disclaimerContent = container.querySelectorAll(
            "[video-embed-disclaimer-content]",
          );
          let disclaimerOverlay = container.querySelectorAll(
            "[video-embed-disclaimer-overlay]",
          );

          for (var j = 0; j < disclaimerContent.length; j++) {
            disclaimerContent[j].classList.add(
              "video-embed-disclaimer--is-hidden",
            );
            setTimeout(removeElement.bind(null, disclaimerOverlay[j]), 250);
          }
          playerContainer[0].innerHTML = templateEl[0].innerHTML;
        });
      }
    }

    function removeElement(element) {
      element.remove();
    }
  });
})();
