/**
 * Initiate map toggle button.
 *
 * @function
 * @param {object} dom - The dom elements to toggle.
 * @param {HTMLUListElement} dom.mapBtn - The dom node for the map button.
 * @param {HTMLUListElement} dom.mapCtn - The dom node for the map container.
 * @param {HTMLUListElement} dom.lstBtn - The dom node for the list button.
 * @param {HTMLUListElement} dom.lstCtn - The dom node for the list container.
 */
function initMapBtn({ mapBtn, mapCtn, lstBtn, lstCtn }) {
  mapBtn.addEventListener("click", (e) => {
    e.preventDefault();

    const btn = e.target.closest("button");

    lstBtn.classList.remove("button--is-dark");
    lstBtn.classList.remove("button-map-list--active");
    lstBtn.classList.add("button--is-white");
    lstBtn.classList.add("button--with-border");
    lstBtn.classList.add("button-map-list--inactive");
    lstCtn.classList.add("d-none");

    btn.classList.remove("button--is-white");
    btn.classList.remove("button--with-border");
    btn.classList.remove("button-map-map--inactive");
    btn.classList.add("button--is-dark");
    btn.classList.add("button-map-map--active");

    mapCtn.classList.remove("d-none");
  });
}

/**
 * Initiate list toggle button.
 *
 * @function
 * @param {object} dom - The dom elements to toggle.
 * @param {HTMLUListElement} dom.mapBtn - The dom node for the map button.
 * @param {HTMLUListElement} dom.mapCtn - The dom node for the map container.
 * @param {HTMLUListElement} dom.lstBtn - The dom node for the list button.
 * @param {HTMLUListElement} dom.lstCtn - The dom node for the list container.
 */
function initLstBtn({ mapBtn, mapCtn, lstBtn, lstCtn }) {
  lstBtn.addEventListener("click", (e) => {
    e.preventDefault();

    const btn = e.target.closest("button");

    mapBtn.classList.remove("button--is-dark");
    mapBtn.classList.remove("button-map-map--active");
    mapBtn.classList.add("button--is-white");
    mapBtn.classList.add("button--with-border");
    mapBtn.classList.add("button-map-map--inactive");
    mapCtn.classList.add("d-none");

    btn.classList.remove("button--is-white");
    btn.classList.remove("button--with-border");
    btn.classList.remove("button-map-list--inactive");
    btn.classList.add("button--is-dark");
    btn.classList.add("button-map-list--active");

    lstCtn.classList.remove("d-none");
  });
}

/**
 * Set-up toggle buttons.
 */
export default function setupListMapToggle() {
  const mapBtn = document.querySelector('[data-trigger="map"]');
  const mapCtn = document.querySelector('[data-container="map"]');
  const lstBtn = document.querySelector('[data-trigger="list"]');
  const lstCtn = document.querySelector('[data-container="list"]');

  initMapBtn({
    mapBtn,
    mapCtn,
    lstBtn,
    lstCtn,
  });

  initLstBtn({
    mapBtn,
    mapCtn,
    lstBtn,
    lstCtn,
  });
}
