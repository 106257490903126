(function showLikes() {
  if (
    document.querySelectorAll("[data-show-likes]") === null ||
    document.querySelectorAll("[data-show-likes]").length === 0
  ) {
    return;
  }

  const likeButtons = document.querySelectorAll("[data-show-likes]");
  const amountButtons = likeButtons.length;

  const url = "/api/newslike";

  // eslint-disable-next-line no-shadow
  function checkIfIsAllowedToVote(newsId) {
    const key = `likeNews_${newsId}`;
    const result = localStorage.getItem(key);
    return !result;
  }

  for (let i = 0; i < amountButtons; i++) {
    const likeButton = likeButtons[i].querySelector("[data-news]");
    const newsId = likeButton.dataset.news;
    // likeButtons[i].classList.add('has--liked');

    // eslint-disable-next-line radix
    const likeButtonText = likeButton.querySelector("span");

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        news: newsId,
        action: "data",
      }),
      headers: new Headers(),
      // eslint-disable-next-line no-return-assign
    })
      .then((resp) => resp.json())
      .then((data) => (likeButtonText.innerText = data.count))
      .catch(console.error);

    if (!checkIfIsAllowedToVote(newsId)) {
      likeButton.classList.add("has--liked");
    }
  }
})();
