import "leaflet/dist/leaflet";

import { getData } from "./helpers";

import setupListMapToggle from "./initiatives/toggle";
import setupList from "./initiatives/list";
import setupMap from "./initiatives/map";

// Check if we are on the correct page
const mapList = document.querySelector(".js-events");

if (mapList) {
  // Get items from local storage
  const items = JSON.parse(localStorage.getItem("items"));

  // Check if there are items in local storage
  if (!items) {
    const uri = document.querySelector(".js-events").dataset.eventsUrl;
    const events = getData(uri);

    events
      .then((e) => {
        localStorage.setItem("items", JSON.stringify(e));

        setupList(e);
        setupMap({ anchor: "card", items: e });
        return true;
      })
      .catch(console.error);
  } else {
    setupList(items);
    setupMap({ anchor: "card", items });
  }

  setupListMapToggle();
}
