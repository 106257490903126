(function pictureGallery() {
  const container = document.querySelectorAll(".picture-series__container");
  let cardHiddenImages;
  let cardImage;
  const startPages = 3;
  const pageCount = 6;

  container.forEach((element) => {
    cardImage = element.querySelectorAll(".picture-series__image");
    const cardLimit = cardImage.length;
    const loadMoreButton = element.querySelector(".picture-series__more");
    if (loadMoreButton) {
      if (cardLimit <= startPages) {
        loadMoreButton.classList.add("button__not-visible");
      } else {
        cardImage.forEach((item, index) => {
          if (index + 1 > startPages) {
            item.classList.add("picture-series__not-visible");
          }
        });
      }
      loadMoreButton.addEventListener("click", () => {
        cardHiddenImages = [];
        cardHiddenImages = document.querySelectorAll(
          ".picture-series__not-visible",
        );

        cardHiddenImages.forEach((item, index) => {
          if (index + 1 <= pageCount) {
            item.classList.remove("picture-series__not-visible");
          }
        });

        if (cardHiddenImages.length <= pageCount) {
          loadMoreButton.classList.add("button__not-visible");
        }
      });
    }
  });
})();
