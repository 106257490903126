/**
 * Show an onLoad Animation until everything is loaded
 */
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

(function () {
  const page = document.querySelector(".page");
  const onloadContainer = document.querySelector("[data-onload-animation]");
  if (onloadContainer) {
    const { onloadAnimation, onloadFinish, onloadDuration } =
      onloadContainer.dataset;
    disableBodyScroll(page);
    window.addEventListener("load", () => {
      window.setTimeout(() => {
        onloadContainer.classList.add(onloadAnimation);
        window.setTimeout(() => {
          enableBodyScroll(page);
          onloadContainer.classList.add(onloadFinish);
        }, onloadDuration);
      }, 20);
    });
  }
})();
