(function likeNews() {
  if (
    document.querySelectorAll("[data-like-news]") === null ||
    document.querySelectorAll("[data-like-news]").length === 0
  ) {
    return;
  }

  const likeButtons = document.querySelectorAll("[data-like-news]");
  const amountButtons = likeButtons.length;
  const likeButton = document.querySelector("[data-like-news]");
  const newsId = likeButton.dataset.news;
  const newsHash = likeButton.dataset.hash;

  const url = "/api/newslike";

  // eslint-disable-next-line no-shadow
  function checkIfIsAllowedToVote(newsId) {
    const key = `likeNews_${newsId}`;
    const result = localStorage.getItem(key);
    return !result;
  }

  // eslint-disable-next-line no-shadow
  function disableVote(newsId) {
    const key = `likeNews_${newsId}`;
    localStorage.setItem(key, "1");
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < amountButtons; i++) {
    likeButtons[i].addEventListener("click", handleLike);
  }

  function handleLike(e) {
    e.preventDefault();

    if (checkIfIsAllowedToVote(newsId)) {
      for (let i = 0; i < amountButtons; i++) {
        likeButtons[i].classList.add("has--liked");

        // eslint-disable-next-line radix
        const newsLikes = parseInt(likeButtons[i].dataset.likes);
        // eslint-disable-next-line radix
        const likeButtonText = likeButtons[i].querySelector("span");
        const currentLikes = parseInt(likeButtonText.innerText);

        fetch(url, {
          method: "POST",
          body: JSON.stringify({
            news: newsId,
            hash: newsHash,
            action: "count",
          }),
          headers: new Headers(),
          // eslint-disable-next-line no-return-assign
        })
          .then(() => {
            // eslint-disable-next-line no-unused-expressions,no-sequences
            (likeButtonText.innerText = currentLikes + 1),
              (likeButtons[i].dataset.likes = newsLikes + 1),
              disableVote(newsId);
            return true;
          })
          .catch(console.error);
      }
    }
  }

  if (!checkIfIsAllowedToVote(newsId)) {
    for (let i = 0; i < amountButtons; i++) {
      likeButtons[i].classList.add("has--liked");
    }
  }
})();
