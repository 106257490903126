(function quickFixLinks() {
  const modules = document.querySelectorAll("[data-quick-fix-links]");

  modules.forEach((module) => {
    const aTag = module.querySelectorAll("a");

    aTag.forEach((link) => {
      if (link.classList.length <= 0) {
        link.classList.add("link--wave");
      }
    });
  });
})();
