(function loadMoreNews() {
  if (
    document.querySelector("[data-api-url]") === null ||
    document.querySelector("[data-api-url]").length === 0
  ) {
    return;
  }

  const loadMoreButton = document.querySelector("[data-load-more]");
  const dataWrap = document.querySelector("[data-api-url]");
  const { tag, category, apiUrl } = dataWrap.dataset;
  const startItems = 4;
  const loadAmountItems = 6;
  // eslint-disable-next-line no-unused-vars
  let cardAmount = startItems;

  async function fetchMoviesJSON() {
    if (!dataWrap.hasAttribute("data-count-news")) {
      dataWrap.dataset.countNews = 0;
    } else if (dataWrap.dataset.countNews === "0") {
      dataWrap.dataset.countNews = startItems;
    } else if (dataWrap.dataset.countNews === startItems) {
      dataWrap.dataset.countNews =
        parseInt(dataWrap.dataset.countNews, 10) + loadAmountItems;
    }

    const response = await fetch(
      `${apiUrl + dataWrap.dataset.countNews}/${tag}/${category}`,
    );
    return response.json();
  }

  fetchMoviesJSON()
    .then((items) => {
      dataWrap.setAttribute("data-total-news", items.totalNews);
      // eslint-disable-next-line radix,no-use-before-define
      if (items.totalNews <= startItems) {
        loadMoreButton.disabled = true;
      }

      // eslint-disable-next-line no-use-before-define
      buttonEvents();
      return true;
    })
    .catch(console.error);

  function loadMore(e) {
    e.preventDefault();

    if (dataWrap.dataset.countNews === "0") {
      dataWrap.dataset.countNews = startItems;
    } else {
      dataWrap.dataset.countNews =
        parseInt(dataWrap.dataset.countNews, 10) + loadAmountItems;
    }

    fetch(`${apiUrl + dataWrap.dataset.countNews}/${tag}/${category}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success !== true) {
          return;
        }

        loadMoreButton.blur();

        data.data.forEach((element) => {
          dataWrap.firstElementChild.insertAdjacentHTML("beforeend", element);

          // eslint-disable-next-line no-plusplus
          cardAmount++;
        });

        // eslint-disable-next-line radix,max-len
        if (
          data.data.length < loadAmountItems ||
          cardAmount === parseInt(dataWrap.dataset.totalNews)
        ) {
          loadMoreButton.disabled = true;
        }
        return true;
      })
      .catch(console.error);

    // eslint-disable-next-line no-use-before-define
    buttonEvents();
  }

  function buttonEvents() {
    // eslint-disable-next-line radix
    if (
      loadMoreButton.disabled === false ||
      parseInt(dataWrap.dataset.totalNews) > startItems
    ) {
      loadMoreButton.addEventListener("click", loadMore);
    }
  }
})();
