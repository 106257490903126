// import Swiper JS
import Swiper from "swiper";

const slides = document.querySelectorAll(".quote-slider .swiper-slide");

if (slides.length !== 0) {
  const slider = document.querySelector(".quote-slider__wrapper");

  if (slides.length > 1) {
    new Swiper(".quote-slider", {
      loop: true,

      // arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  } else {
    slider.classList.add("no--sliding");
  }
}
