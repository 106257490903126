(function ageGate() {
  // Get gate
  const gate = document.querySelector(".js-age-gate");

  if (gate) {
    // Add scroll lock to body element
    document.body.classList.add("age-gate--scroll-lock");

    // Get the buttons
    const btns = gate.querySelectorAll(".js-button");

    // TODO This code was broken in the old code and lead to exceptions (live!)
    // Get the label
    // const label = gate.querySelector(".js-label");
    // const switchLabel = function switchLabel() {
    //   // Get the next label URL
    //   const { labelUrl } = gate.dataset;
    //
    //   // Set the label URL to the current label src
    //   gate.setAttribute("data-label-url", label.src);
    //
    //   // Switch the label src
    //   label.src = labelUrl;
    // };

    const dataLayer = function dataLayer(type) {
      // Set the window.dataLayer
      window.dataLayer = window.dataLayer || [];

      // Set the button type
      const button = type === "consent" ? "ueber-16" : "unter-16";

      // Push event to window.dataLayer
      window.dataLayer.push({
        event: "agegate",
        button,
      });
    };

    btns.forEach((btn) => {
      // btn.addEventListener("mouseover", switchLabel);
      // btn.addEventListener("mouseleave", switchLabel);

      if (btn.dataset.type === "consent") {
        btn.addEventListener("click", () => {
          // Milliseconds per week
          const week = 7 * 24 * 60 * 60 * 1000;

          // Get expiration date
          const expires = new Date(Date.now() + week);

          // Set cookie for age verification
          document.cookie = `flens-age=1;path=/;expires=${expires.toUTCString()}`;

          // Remove scroll lock from body element
          document.body.classList.remove("age-gate--scroll-lock");

          // Track the button
          dataLayer("consent");

          // Remove gate from the DOM
          gate.parentNode.removeChild(gate);
        });
      } else {
        btn.addEventListener("click", (e) => {
          e.preventDefault();

          // Get the URL of the link
          const { href } = e.target.closest("a");

          // Track the button
          dataLayer("dissent");

          // Navigate to the URL
          window.location.href = href;
        });
      }
    });
  }
})();
