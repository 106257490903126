import { html, LitElement } from "lit";
import { ref, createRef } from "lit/directives/ref.js";
import styles from "./hero-styles";

/**
 * A badge web component.
 */
export class FlensHero extends LitElement {
  static properties = {
    link: { type: String },
    times: { type: Array },
    productImages: { type: Array, attribute: "product-images" },
    productAssets: { type: Array, attribute: "product-assets" },
    sky: { type: String },
    beach: { type: String },
    text1: { type: String },
    text2: { type: String },
    seagull: { type: String },
    people: { type: String },
    sand: { type: String },
    dune: { type: String },
    bole: { type: String },
  };

  static styles = [styles];

  constructor() {
    super();
    this.link = "";
    this.times = [];
    this._productImages = [];
    this.productAssets = [];
    this._sky = "";
    this._beach = "";
    this._text1 = "";
    this._text2 = "";
    this._seagull = "";
    this._people = "";
    this._sand = "";
    this._dune = "";
    this._bole = "";
    this._heroRef = createRef();
    this._sentinelRef = createRef();
  }

  /**
   * @returns {string[]}
   * @private
   */
  get productImages() {
    return (
      this._getItemByTime(this.productAssets)?.images ?? this._productImages
    );
  }

  /**
   * @param {string[]} srcs
   */
  set productImages(srcs) {
    this._productImages = srcs;
  }

  get sky() {
    return this._getItemImage("sky", this._sky);
  }

  set sky(src) {
    this._sky = src;
  }

  get beach() {
    return this._getItemImage("beach", this._beach);
  }

  set beach(src) {
    this._beach = src;
  }

  get text1() {
    return this._getItemImage("text_1", this._text1);
  }

  set text1(src) {
    this._text1 = src;
  }

  get text2() {
    return this._getItemImage("text_2", this._text2);
  }

  set text2(src) {
    this._text2 = src;
  }

  get seagull() {
    return this._getItemImage("seagull", this._seagull);
  }

  set seagull(src) {
    this._seagull = src;
  }

  get people() {
    return this._getItemImage("people", this._people);
  }

  set people(src) {
    this._people = src;
  }

  get sand() {
    return this._getItemImage("sand", this._sand);
  }

  set sand(src) {
    this._sand = src;
  }

  get dune() {
    return this._getItemImage("dune", this._dune);
  }

  set dune(src) {
    this._dune = src;
  }

  get bole() {
    return this._getItemImage("bole", this._bole);
  }

  set bole(src) {
    this._bole = src;
  }

  /**
   * @param {string} s E. g. "01.01.1970;22:10".
   * @returns {Date|undefined}
   * @private
   */
  _parseBeTime(s) {
    if (!s) {
      return;
    }
    // const [date, time] = s.split(";");
    // const day = parseInt(date.split(".")[0]);
    const [hour = 0, minute = 0] =
      s
        .split(";")[1]
        ?.split(":")
        ?.map((p) => parseInt(p)) ?? [];
    return new Date(1970, 1, 1, hour, minute, 0, 0);
  }

  /**
   * @param {object[]} array
   * @private
   */
  _getItemByTime(array) {
    const current = new Date();
    current.setFullYear(1970, 1, 1);
    // current.setHours(23, 0);
    current.setSeconds(0, 0);
    return array.find(
      ({ start, end }) =>
        start &&
        end &&
        current >= this._parseBeTime(start) &&
        current <= this._parseBeTime(end),
    );
  }

  /**
   * @param {HeroItemType} item
   * @param {string} defaultAsset
   * @returns {string}
   * @private
   */
  _getItemImage(item, defaultAsset) {
    return (
      this._getItemByTime(this.times)?.assets?.find(({ id }) => id === item)
        ?.asset ?? defaultAsset
    );
  }

  /**
   * @param {string} className
   * @param {...{src:string, alt:string, wrapper?:boolean, lazy?:boolean}} images
   * @returns {TemplateResult<1>|string}
   * @private
   */
  _renderItem(className, ...images) {
    return this._renderOptionalLinkItem(className, undefined, ...images);
  }

  /**
   * @param {string} className
   * @param {string|undefined} href
   * @param {...{src:string, alt:string, wrapper?:boolean, lazy?:boolean}} images
   * @returns {TemplateResult<1>|string}
   * @private
   */
  _renderOptionalLinkItem(className, href, ...images) {
    if (images.length === 0) {
      return "";
    }
    const contents = images.map(({ src, alt, wrapper, lazy }, i) =>
      this._renderImage(src, alt, `${className}-${i + 1}`, wrapper, lazy),
    );
    return href
      ? html` <a class="hero__item ${className}" href="${href}">${contents}</a>`
      : html` <div class="hero__item ${className}">${contents}</div>`;
  }

  _renderImage(src, alt, className, wrapper = false, lazy = false) {
    return src
      ? wrapper
        ? html`<div class="${className}">
            <img
              src="${src}"
              alt="${alt}"
              loading="${lazy ? "lazy" : "eager"}"
            />
          </div>`
        : html`<img
            src="${src}"
            alt="${alt}"
            class="${className}"
            loading="${lazy ? "lazy" : "eager"}"
          />`
      : "";
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);

    // This observer toggles an animation of the "product" and "bole" items
    this._observer = new IntersectionObserver(
      ([{ isIntersecting }]) =>
        this._heroRef.value.classList.toggle("animated", isIntersecting),
      { threshold: 1 },
    );
    this._observer.observe(this._sentinelRef.value);
  }

  render() {
    return html`
      <div class="hero" ${ref(this._heroRef)}>
        ${this._renderItem("hero__sky", { src: this.sky, alt: "Himmel" })}
        ${this._renderItem("hero__beach", { src: this.beach, alt: "Strand" })}
        ${this._renderItem(
          "hero__text",
          { src: this.text1, alt: "Text 1" },
          { src: this.text2, alt: "Text 2", wrapper: true },
        )}
        ${this._renderItem("hero__seagull", { src: this.seagull, alt: "Möwe" })}
        ${this._renderItem("hero__people", { src: this.people, alt: "Leute" })}
        ${this._renderItem("hero__sand", { src: this.sand, alt: "Sand" })}
        ${this._renderItem("hero__dune", { src: this.dune, alt: "Düne" })}
        ${this._renderOptionalLinkItem("hero__bole", this.link, {
          src: this.bole,
          alt: "Poller",
        })}
        ${this._renderItem(
          "hero__product",
          ...this.productImages.map((src, i) => ({
            src,
            alt: "Produkt",
            lazy: i > 0,
          })),
        )}
      </div>
      <div class="sentinel" ${ref(this._sentinelRef)}></div>
    `;
  }
}

customElements.define("flens-hero", FlensHero);

/**
 * @typedef {'sky'|'beach'|'text_1'|'text_2'|'seagull'|'people'|'dune'|'bole'|'product'} HeroItemType
 */
