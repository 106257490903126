(function navigationTransparent() {
  const navigationContainer = document.querySelectorAll(".js-navigation");
  const heroContainer = document.querySelectorAll(".js-hero");

  if (heroContainer.length <= 0 || navigationContainer.length <= 0) {
    return false;
  }

  for (let i = 0; i < navigationContainer[0].classList.length; i++) {
    if (/background-color--.*/.test(navigationContainer[0].classList[i])) {
      var matchArray = /background-color--.*/.exec(
        navigationContainer[0].classList[i],
      );

      for (let j = 0; j < matchArray.length; j++) {
        navigationContainer[0].classList.remove(matchArray[j]);
      }
      navigationContainer[0].classList.add("background-color--transparent");
      break;
    }
  }
})();
